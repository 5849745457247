<template>
    <div class="vw-100 vh-100" style="background-color: #020814; color: white;">
        <el-amap :center="map.center" :zoom="15" map-style="amap://styles/darkblue" class="sc-amap">
            <el-amap-marker v-for="(company, i) in $store.state.companies.filter(p=>p.longitude)" :key="'amapMarker_'+i"
                :position="[company.longitude,company.latitude]" />
            <el-amap-text v-for="(company, i) in $store.state.companies.filter(p=>p.longitude)" :key="'amapText_'+i"
                :text="company.fullName+'<div class=\'text-sm o-50\'>'+company.phone+'<br/>'+company.address+'</div>'"
                :position="[company.longitude,company.latitude]" :offset="[0,-80]" />
        </el-amap>

        <system-bar />

        <navigation-bar active="/screen/ua" />

        <div class="position-fixed" style="top: 16px; left: 16px; width: 400px;">
            <div class="sc-border-box">
                <div class="dv-border-box-body py-4">
                    <div class="py-2 text-center">
                        <el-dropdown trigger="click" placement="bottom" @command="changeCompany">
                            <a href="javascript:void(0)" class="text-md">
                                {{companyName}}
                                <i class="el-icon-arrow-down"></i>
                            </a>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item v-for="company in $store.state.companies" :key="company.id"
                                        :command="company"
                                        :class="{'bg-primary-light text-primary':company.id==companyId}">
                                        {{company.name}}
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>
                </div>
            </div>

            <div class="sc-border-box mt-3">
                <div class="dv-border-box-header">
                    <div class="row no-gutters">
                        <div class="col">个人风险预警</div>
                        <div class="col">企业风险预警</div>
                    </div>
                </div>
                <div class="dv-border-box-body">
                    <div class="row no-gutters">
                        <div class="col">
                            <echarts :option="individualUserRiskChartOption" style="height: 160px;" />
                        </div>
                        <div class="col">
                            <echarts :option="enterpriseUserRiskChartOption" style="height: 160px;" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="sc-border-box mt-3">
                <div class="dv-border-box-header">注册用户</div>
                <div class="dv-border-box-body px-3 pb-3" style="height: 480px;">
                    <dv-scroll-board :config="registeredUserConfig" class="h-100" />
                </div>
            </div>
        </div>

        <div class="position-fixed" style="top: 64px; right: 16px; width: 400px;">
            <div class="sc-border-box">
                <div class="dv-border-box-body py-4">
                    <div class="row no-gutters text-center">
                        <div class="col">
                            <div style="font-size: 32px;">
                                {{userNumberStats.managers}}
                            </div>
                            <div class="text-sm o-50">管理员</div>
                        </div>
                        <div class="col">
                            <div style="font-size: 32px;">
                                {{userNumberStats.registeredUsers}}
                            </div>
                            <div class="text-sm o-50">注册用户</div>
                        </div>
                        <div class="col">
                            <div style="font-size: 32px;">
                                {{userNumberStats.individualUsers}}
                            </div>
                            <div class="text-sm o-50">已实名个人</div>
                        </div>
                        <div class="col">
                            <div style="font-size: 32px;">
                                {{userNumberStats.enterpriseUsers}}
                            </div>
                            <div class="text-sm o-50">已实名企业</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="sc-border-box mt-3">
                <div class="dv-border-box-body">
                    <div class="row no-gutters text-center my-4">
                        <div class="col">
                            <div style="font-size: 32px;">
                                <font-awesome-icon icon="user-friends" class="sc-text-primary" />
                                {{userNumberStats.individualFarmers}}
                            </div>
                            <div class="text-sm mt-2 o-50">个人种植户</div>
                        </div>
                        <div class="col-auto border-left border-primary o-25"></div>
                        <div class="col">
                            <div style="font-size: 32px;">
                                <font-awesome-icon icon="user-friends" class="sc-text-primary" />
                                {{userNumberStats.enterpriseFarmers}}
                            </div>
                            <div class="text-sm mt-2 o-50">企业种植户</div>
                        </div>
                    </div>
                    <div class="border-top border-primary my-4 o-25"></div>
                    <div class="row no-gutters text-center my-4">
                        <div class="col">
                            <div style="font-size: 32px;">
                                <font-awesome-icon icon="user-tie" class="sc-text-primary" />
                                {{userNumberStats.warrantors}}
                            </div>
                            <div class="text-sm mt-2 o-50">已认证担保人</div>
                        </div>
                        <div class="col-auto border-left border-primary o-25"></div>
                        <div class="col">
                            <div style="font-size: 32px;">
                                <font-awesome-icon icon="user-graduate" class="sc-text-primary" />
                                {{userNumberStats.experts}}
                            </div>
                            <div class="text-sm o-50">已认证专家</div>
                        </div>
                    </div>
                    <div class="border-top border-primary my-4 o-25"></div>
                    <div class="row no-gutters text-center my-4">
                        <div class="col">
                            <div style="font-size: 32px;">
                                <font-awesome-icon icon="truck" class="sc-text-primary" />
                                {{userNumberStats.individualSuppliers}}
                            </div>
                            <div class="text-sm mt-2 o-50">个人供应商</div>
                        </div>
                        <div class="col-auto border-left border-primary o-25"></div>
                        <div class="col">
                            <div style="font-size: 32px;">
                                <font-awesome-icon icon="truck" class="sc-text-primary" />
                                {{userNumberStats.enterpriseSuppliers}}
                            </div>
                            <div class="text-sm mt-2 o-50">企业供应商</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="sc-border-box mt-3">
                <div class="dv-border-box-header">累计注册用户</div>
                <div class="dv-border-box-body">
                    <echarts :option="registeredUserChartOption" style="height: 200px;" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SystemBar from './components/SystemBar.vue';
    import NavigationBar from './components/NavigationBar.vue';

    export default {
        data() {
            return {
                map: {
                    center: [87.518146, 43.843489],
                },
                companyId: this.$store.state.companyId,
                companyName: this.$store.state.companyName,
                userNumberStats: {
                    managers: 0,
                    registeredUsers: 0,
                    individualUsers: 0,
                    enterpriseUsers: 0,
                    individualFarmers: 0,
                    enterpriseFarmers: 0,
                    individualSuppliers: 0,
                    enterpriseSuppliers: 0,
                    warrantors: 0,
                    experts: 0,
                },
                registeredUserConfig: {
                    header: ['名称', '注册手机', '注册日期'],
                    data: [],
                    align: ['left', 'left', 'right'],
                    rowNum: 8,
                    headerBGC: '#334780',
                    oddRowBGC: 'transparent',
                    evenRowBGC: 'transparent',
                },
                individualUserRiskChartOption: {},
                registeredUserChartOption: {},
                enterpriseUserRiskChartOption: {},
            };
        },
        components: { SystemBar, NavigationBar },
        methods: {
            changeCompany(company) {
                this.companyId = company.id;
                this.companyName = company.name;

                if (company.longitude) {
                    this.map.center = [company.longitude, company.latitude];
                }

                this.loadUserNumberStats();
                this.loadSimpleRegisteredUsers();
                this.loadIndividualUserRiskStats();
                this.loadRegisteredUserStats();
                this.loadEnterpriseUserRiskStats();
            },
            async loadUserNumberStats() {
                let response = await this.$axios.get('/api/Stats/GetUserNumberStats', { params: { companyId: this.companyId } });
                this.userNumberStats = response.data;
            },
            async loadSimpleRegisteredUsers() {
                let response = await this.$axios.get('/api/Stats/GetSimpleRegisteredUsers', { params: { companyId: this.companyId } });
                this.registeredUserConfig = {
                    header: ['名称', '注册手机', '注册日期'],
                    data: response.data.map(p => ([p.name, p.phone, p.date])),
                    align: ['left', 'left', 'right'],
                    rowNum: 8,
                    headerBGC: '#334780',
                    oddRowBGC: 'transparent',
                    evenRowBGC: 'transparent',
                };
            },
            async loadIndividualUserRiskStats() {
                let response = await this.$axios.get('/api/Stats/GetIndividualUserRiskStats', { params: { companyId: this.companyId } });
                let result = response.data;
                this.individualUserRiskChartOption = {
                    tooltip: {},
                    series: [{
                        type: 'pie',
                        label: { show: false },
                        labelLine: { show: false },
                        data: result.map(p => ({ name: p.key, value: p.value }))
                    }]
                };
            },
            async loadRegisteredUserStats() {
                let response = await this.$axios.get('/api/Stats/GetRegisteredUserStats', { params: { companyId: this.companyId } });
                let result = response.data;
                this.registeredUserChartOption = {
                    grid: { left: '60px', right: '20px', top: '10px', bottom: '40px' },
                    tooltip: {},
                    xAxis: { data: result.map(p => p.key) },
                    yAxis: {},
                    series: [{
                        type: 'line',
                        smooth: true,
                        areaStyle: {},
                        data: result.map(p => p.value)
                    }]
                };
            },
            async loadEnterpriseUserRiskStats() {
                let response = await this.$axios.get('/api/Stats/GetEnterpriseUserRiskStats', { params: { companyId: this.companyId } });
                let result = response.data;
                this.enterpriseUserRiskChartOption = {
                    tooltip: {},
                    series: [{
                        type: 'pie',
                        label: { show: false },
                        labelLine: { show: false },
                        data: result.map(p => ({ name: p.key, value: p.value }))
                    }]
                };
            },
        },
        created() {
            document.title = this.$store.state.applicationName;

            let companies = this.$store.state.companies.filter(p => p.id == this.companyId);
            if (companies.length == 0) {
                this.changeCompany(this.$store.state.companies[0]);
            }
            else {
                this.changeCompany(companies[0]);
            }
        },
    };
</script>