<template>
    <div class="position-fixed d-flex justify-content-center text-center"
        style="left: 32px; right: 32px; bottom: 32px;">
        <router-link to="/dp1" class="mx-3 navigation-button" :class="{'navigation-button-active':active=='/dp1'}">
            <div class="navigation-button-text">土地流转</div>
            <div class="navigation-button-subtext">SCREEN1</div>
        </router-link>
        <router-link to="/dp2" class="mx-3 navigation-button" :class="{'navigation-button-active':active=='/dp2'}">
            <div class="navigation-button-text">供应商分析</div>
            <div class="navigation-button-subtext">SCREEN2</div>
        </router-link>
        <router-link to="/" class="mx-3 navigation-button" :class="{'navigation-button-active':active=='/'}">
            <img src="@/assets/logo-screen.png" class="d-block" style="height: 40px; margin-bottom: -4px;" />
        </router-link>
        <router-link to="/dp3" class="mx-3 navigation-button" :class="{'navigation-button-active':active=='/dp3'}">
            <div class="navigation-button-text">农时分析</div>
            <div class="navigation-button-subtext">SCREEN3</div>
        </router-link>
        <router-link to="/screen/ua" class="mx-3 navigation-button"
            :class="{'navigation-button-active':active=='/screen/ua'}">
            <div class="navigation-button-text">用户分析</div>
            <div class="navigation-button-subtext">USER ANALYSIS</div>
        </router-link>
    </div>
</template>

<script>
    export default {
        data() {
            return {
            };
        },
        props: {
            active: { type: String },
        },
    };
</script>

<style>
    .navigation-button {
        display: inline-block;
        padding: 8px 16px;
        border-bottom: 4px solid #00a7d2;
        border-radius: 10px;
        background-color: #000000;
        box-shadow: 0 0 8px #00a7d2;
    }

    .navigation-button:hover {
        text-decoration: none;
        box-shadow: 0 0 24px #00a7d2;
    }

    .navigation-button-active {
        border-color: #67c23a;
    }

    .navigation-button-text {
        color: white;
    }

    .navigation-button-subtext {
        color: #00a7d2;
        font-size: 8px;
    }
</style>